<template>
    <li class="nav-item dropdown">
        <div class="d-flex flex-row align-items-center">
            <a class="flex-grow-1 nav-link" :href="'/category/'+category.id">{{ category.name }}</a>
            <a class="dropdown-toggle p-2 text-dark" href="#" @click.prevent="toggleShow" v-if="category.children.length"></a>
        </div>
        <ul class="dropdown-menu" :class="{ show: isShowing }">
            <li v-for="(subcategory, index) in category.children" :key="index">
                <navbar-item v-if="subcategory.children" :category="subcategory"></navbar-item>
                <router-link class="dropdown-item" :to="'/category/'+subcategory.id" v-else>
                    {{ subcategory.name }}
                </router-link>
            </li>
        </ul>
    </li>
</template>
  
<script>
/* eslint-disable */
export default {
    name: 'navbar-item',
    props: ['category'],
    data() {
        return {
            isShowing: false,
        };
    },
    methods: {
        toggleShow() {
            this.isShowing = !this.isShowing;
        }
    },
    components: {
        'navbar-item': () => import('./MobileNavbarItem.vue'), // assuming the file name is NavbarItem.vue
    }
};
</script>

<style scoped>
    .nav-item {
        border-bottom: 1px solid #efefef;
    }

    .dropdown-menu {
        border: none;
        padding: 0;        
    }

    .dropdown-menu li {
        padding: 5px;
        border-bottom: 1px solid #efefef;
    }
</style>
  