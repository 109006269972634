export function getThumbnail(article) {
    if (article.thumbnail != null) {
        return article.thumbnail;
    }

    const baseURL = window.location.origin;
    const relativePath = '/thumbnails/';
    const thumbnailURL = `${baseURL}${relativePath}${article.id}.png?t=`+Date.now();

    // Return an empty string temporarily (the actual URL will be returned in the onload or onerror event)
    return thumbnailURL;
}

export function getArticleLink(article) {
    return "/article/" + article.id;
}

/* eslint-disable */
export function escapeRegExp(text) {
    return text.replace(/\&|\./g, '\\$&');
}