<template>
    <div>
        <nav class="navbar navbar-light">
            <div class="container">
                <div class="w-100 d-flex justify-content-end">
                    <button class="navbar-toggler" type="button" @click="showMenu = !showMenu" :aria-expanded="showMenu">
                        <font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon>
                    </button>
                </div>
                <div class="collapse navbar-collapse" :class="{ show: showMenu }" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <navbar-item v-for="(category, index) in categories" :key="index"
                            :category="category"></navbar-item>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import NavbarItem from './MobileNavbarItem.vue';

export default {
    components: {
        NavbarItem
    },
    data() {
        return {
            showMenu: false
        }
    },
    computed: {
        categories() {
            return this.$store.state.categories
        }
    }
}
</script>
<style scoped>
    .navbar-nav {
        background: #fbfbfb;
        padding: 15px;
        margin-top: 5px;
        font-weight: 500;
    }
</style>