<template>
    <div id="footer" class="bg-dark">
        <div class="container justify-content-center d-flex gap g-3 policy">
            <router-link to="/policies/terms">Terms and Conditions</router-link>
            <router-link to="/policies/cookies">Cookies policy</router-link>
            <router-link to="/policies/privacy">Privacy policy</router-link>
        </div>
    </div>
</template>
<style scoped>
    #footer {
        min-height: 40px;
        z-index: -1;
        margin-top: 30px;
    }

    .policy a {
        text-decoration: none;
        color: white;
    }
    .policy a:hover {
        text-decoration: underline;
    }
</style>