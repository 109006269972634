<template>
    <div class="container">
        <form @submit.prevent="search">
            <div class="input-group border">
                <input type="text" class="form-control border-0 rounded-0" placeholder="Search for an article" v-model="searchValue" />
                <div class="input-group-append">
                    <button class="btn rounded-0" type="submit">Search</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchValue: ''
            }
        },
        methods: {
            search() {
                this.$router.push({ name: 'search-results', params: { query: this.searchValue } });
            }
        }
    }
</script>

<style scoped>
.input-group {
    width: 100%;
}
.input-group > .form-control {
    border-right: 0; /* Remove right border from input */
}
.input-group-append > .btn {
    border-left: 0; /* Remove left border from button */
}
</style>
