import { api } from '@/services/api'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'TheTrendyTales' }
  },
  {
    path: '/category/:category',
    name: 'Category',
    component: () => import('@/views/Category.vue'),
    meta: { title: 'TheTrendyTales' }
  },
  {
    path: '/article/:article',
    name: 'Article',
    component: () => import('@/views/Article.vue'),
    meta: { title: 'TheTrendyTales loading..' }
  },
  {
    path: '/edit/:articleId',
    name: 'edit',
    component: () => import('@/views/EditArticle.vue'),
    meta: { title: 'TheTrendyTales loading..' }
  },
  {
    path: '/create',
    name: 'create',
    component: () => import('@/views/CreateArticle.vue'),
    meta: { title: 'TheTrendyTales loading..' }
  },
  {
    path: '/search/:query',
    name: 'search-results',
    component: () => import('@/views/SearchResults.vue'),
    meta: { title: 'TheTrendyTales search results' }
  },
  {
    path: '/showAll',
    name: 'showAll',
    component: () => import('@/views/showAll.vue'),
  },
  {
    path: '/policies/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
    meta: { title: 'TheTrendyTales Terms and Conditions' }
  },
  {
    path: '/policies/cookies',
    name: 'Cookies policy',
    component: () => import('@/views/Cookies.vue'),
    meta: { title: 'TheTrendyTales Cookies policy' }
  },
  {
    path: '/policies/privacy',
    name: 'Privacy policy',
    component: () => import('@/views/Privacy.vue'),
    meta: { title: 'TheTrendyTales Privacy policy' }
  },
  // Add more routes as needed
  {
    path: '/:pathMatch(.*)*',  // Catch-all route
    name: 'NotFound',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
})

var externalRedirectURL = null;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'TheTrendyTales';
  window.scrollTo(0, 0);
  if (to.fullPath.startsWith("/article/")) {
    if (typeof to.query.direct !== typeof undefined) {
      let newQuery = {...to.query};
      // The 'direct' query parameter is present, so let's remove it
      delete newQuery.direct;
      try {
        let link = await api.get(`article/${to.params.article}/offer-link`);
        // Store the URL you want to redirect to in a variable
        externalRedirectURL = link.offer_link;
        // Redirect to the same page but without the 'direct' query parameter
        next({name: to.name, params: to.params, query: newQuery});
      } catch(e) {
        console.log(e);
        next();
      }
    } else {
      // If externalRedirectURL is not null, redirect to it
      if (externalRedirectURL) {
        let link = externalRedirectURL+"";
        externalRedirectURL = null;
        window.location.href = link;
      }
      next();
    }
  } else {
    next();
  }
});

export default router