<template>
    <div>
        <SectionHeader :name="category.name" :seperatorColor="seperatorColor"></SectionHeader>
        <div>
          <div class="row g-2">
            <template v-for="(article, index) in categoriesArticles[category.id]" :key="article">
              <Main_Article :article="article" class="col-12" v-if="index == 0"></Main_Article>
              <Article_Horizontal :article="article" class="col-12" v-else></Article_Horizontal>
            </template>
          </div>
        </div>
    </div>
</template>
<script>
  /* eslint-disable */
  import SectionHeader from '@/components/SectionHeader.vue';
  import Article_Horizontal from './Articles/Article_Horizontal.vue';
  import Main_Article from '@/components/Section/Articles/Article_Main.vue'

  export default {
    components: {
      Main_Article,
      Article_Horizontal,
      SectionHeader
    },
    props: {
      category: Object,
      seperatorColor: {
          type: String,
          default: () => "crimson"
      }
    },
    computed: {
      categoriesArticles() {
        return this.$store.state.categoriesArticles;
      }
    }
  }
</script>