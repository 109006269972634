<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-3 col-lg-2 p-2 text-center">
                <router-link to="/"><img :src="logo" class="logo-img w-100" /></router-link>
            </div>
            <div class="d-none d-lg-block col-lg-10">
                <Navbar />
            </div>
            <div class="d-block d-lg-none">
                <MobileNavbar></MobileNavbar>
            </div>
        </div>
    </div>
</template>

<script>
    import Navbar from "@/components/Header/Navbar.vue"
    import MobileNavbar from "@/components/Header/MobileNavbar.vue"
    export default {
        components: {
            Navbar,
            MobileNavbar
        },
        computed: {
            logo() {
                return require('@/assets/logo.png');
            }
        }
    }
</script>
<style scoped>
    .logo-img {
        max-width: 200px;
    }
</style>