<template>
  <div class="position-relative">
    <router-link :to="getArticleLink(article)" class="article-link">
      <div class="thumb-container ratio-16x9">
        <img :src="getThumbnail(article)" class="thumb-image" />
        <div class="thumb-image-overlay"></div>
      </div>
      <div class="thumb-title" :title="article.title">
        {{ article.title }}
      </div>
    </router-link>
    <div class="article-brand" v-if="article.offer != null">
      <a :href="article.offer.link" class="link text-dark">Visit {{ article.offer.brand_url }}</a>
    </div>
  </div>
</template>
<script>
import { getThumbnail, getArticleLink } from '@/services/article'
export default {
  props: {
    article: Object
  },
  methods: {
    getThumbnail,
    getArticleLink
  }
}
</script>
<style scoped>
.article-brand {
  font-family: 'Cabin', sans-serif;
  text-align: left;
}

.article-brand a {
  text-decoration: none;
}

.article-brand a:hover {
  text-decoration: underline;
}

.thumb-title {
  text-overflow: ellipsis;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  padding: 20px;
  font-size: 2.2rem;
  color: #fff;
  font-weight: bold;
  z-index: 2;
}

.thumb-container {
  overflow: hidden;
  position: relative;
  background: #f7f7f7;
}

.thumb-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 100%;
}

.thumb-image-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #FFFFFF1A 0%, #000000C9 100%);
  opacity: 0.3;
  z-index: 1;
  transition: opacity 0.5s ease;
}

.article-link:hover .thumb-image-overlay {
  opacity: 0.5;
}
</style>
<style>
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    /* .thumb-container.ratio-16x9 {
        padding-top: 75%
      } */

    .thumb-title {
      font-size: 1.5rem !important;
    }

  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .thumb-title {
      font-size: 2.2rem !important;
    }

  }

  @media screen and (max-width: 767px) {
    .thumb-title {
      font-size: 1.5rem !important;
    }

  }
</style>