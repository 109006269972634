<template>
  <div class="loading">
    <img :src="Logo" style="width: 250px;" />
    <font-awesome-icon icon="fa-solid fa-spinner" class="fa-spin mt-3" />
  </div>
</template>
<script>
  export default {
    computed: {
      Logo() {
        return require('@/assets/logo.png');
      }
    }
  }
</script>
<style scoped>
.loading {
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0.5;
  z-index: 9999;
}

.fa-spinner {
  font-size: 3rem;
  color: black;
}
</style>
  