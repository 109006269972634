<template>
  <div class="home">
    <Women_Categories class="d-none d-lg-block mt-3" />
    <Search class="mt-3 mb-3"></Search>
    <div class="container">
      <div class="row">
        <CategorySectionFeatured class="col-lg-6" :category="rootCategories[`Fashion & Style`]"></CategorySectionFeatured>
        <CategorySectionFeatured class="col-lg-6" :category="rootCategories[`Blog`]" seperatorColor="#0e487a"></CategorySectionFeatured>
        <CategorySection class="col-lg-6" :category="rootCategories[`Sport & Fitness`]" seperatorColor="rgb(255 202 0)"></CategorySection>
        <CategorySection class="col-lg-6" :category="rootCategories[`Lifestyle & Travel`]" seperatorColor="rgb(141 13 149)"></CategorySection>
        <CategorySectionBlock class="col-12" :category="rootCategories[`News & Sales`]" seperatorColor="rgb(255 60 60)"></CategorySectionBlock>
      </div>
    </div>
  </div>
</template>
  
<script>
/* eslint-disable */
import Women_Categories from '@/components/Home/Women_Categories'
import Search from '@/components/Home/Search'
import CategorySectionFeatured from '@/components/Section/CategorySectionFeatured'
import CategorySection from '@/components/Section/CategorySection'
import CategorySectionBlock from '@/components/Section/CategorySectionBlock'

export default {
  components: {
    Women_Categories,
    Search,
    CategorySectionFeatured,
    CategorySection,
    CategorySectionBlock
  },
  name: 'Home',
  data() {
    return {
      
    }
  },
  methods: {
    // Define your component's methods here
  },
  computed: {
    rootCategories() {
      return this.$store.state.rootCategories;
    }
  }
}
</script>
  