<template>
    <div class="navbar-article">
        <div class="h-100">
            <div class="d-flex flex-column h-100">
                <div class="thumbnail ratio-16x9">
                    <router-link class="link" :to="getArticleLink(article)"><img :src="getThumbnail(article)"
                            class="thumbnail-image" /></router-link>
                </div>
                <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                    <div class="article-title">
                        <router-link class="link" :to="getArticleLink(article)">{{ article.title }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getThumbnail, getArticleLink } from '@/services/article'
export default {
    props: {
        article: Object
    },
    methods: {
        getThumbnail,
        getArticleLink
    }
}
</script>
<style scoped>
.navbar-article {
    padding: 15px;
}

.thumbnail {
    position: relative;
    overflow: hidden;
}

.thumbnail-image {
    width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.article-title .link {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
}

.article-title {
    text-overflow: ellipsis;
    font-weight: bold;
}
</style>