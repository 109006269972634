<template>
    <div class="d-flex justify-content-center align-items-center align-content-center h-100 position-relative">
        <ul class="gap gx-4 gy-2 list-unstyled m-0 p-0 flex-wrap">
            <Navbar_Item v-for="category in categories" :key="category" :item="category"></Navbar_Item>
        </ul>
    </div>
</template>

<script>
    import Navbar_Item from './Navbar_Item.vue'

    export default {
        components: {
            Navbar_Item
        },
        computed: {
            categories() {
                return this.$store.state.categories
            }
        }
    }
</script>