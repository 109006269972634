const apiBase = 'https://api.thetrendytales.com/';
import Cookies from 'js-cookie'
async function sendRequest(url, method, body = null) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    }
  };
  let token = Cookies.get("token")
  if (token) {
    options.headers = {
      ...options.headers,
      ...{
        "Authorization": token
      }
    };
  }

  if (body) {
    if (body instanceof HTMLFormElement) {
      const formData = new FormData(body);
      const jsonData = {};

      for (let [key, value] of formData.entries()) {
        jsonData[key] = value;
      }

      options.body = JSON.stringify(jsonData);
    } else {
      options.body = JSON.stringify(body);
    }
  }

  const response = await fetch(apiBase + url, options);
  console.log(response);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Check if the response body is empty (e.g., for a DELETE request)
  const contentType = response.headers.get('content-type');
  if (!contentType || !contentType.includes('application/json')) {
    return null;
  }

  return await response.json();
}

export const api = {
  get: (url) => sendRequest(url, 'GET'),
  post: (url, body) => sendRequest(url, 'POST', body),
  put: (url, body) => sendRequest(url, 'PUT', body),
  delete: (url) => sendRequest(url, 'DELETE'),
};
