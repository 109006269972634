<template>
  <Loading v-if="loading" />
  <div v-else>
    <Header></Header>
    <div>
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
/* eslint-disable */
import Loading from './components/Loading.vue'
import Header from './layout/Header.vue'
import Footer from './layout/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Loading,
    Footer
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  beforeMount() {
    this.$store.dispatch('fetchAll');
  }
}
</script>

<style></style>
