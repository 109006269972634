<template>
    <li @mouseleave="updateShowSubMenu(false)">
        <router-link @mouseenter="updateShowSubMenu(true)" @click="updateShowSubMenu(false)" class="navbar-item" :to="'/category/' + item.id">
            {{ item.name }}
            <font-awesome-icon icon="fa-solid fa-angle-down" style="font-size: 13px;" v-if="item.children.length" />
        </router-link>
        <transition name="fade" v-if="item.children.length">
            <div class="navbar-item-submenu position-absolute w-100 bg-white" style="left: 0" v-show="showSubMenu">
                <div class="d-flex bg-light h-100 w-100">
                    <div class="col-lg-6 position-relative overflow-hidden">
                        <img :src="getImageUrl(item.category)" class="img-fit" />
                        <div class="cover">
                            <div class="title">
                                {{ item.name }}
                            </div>
                            <div class="row g-2 mt-2">
                                <div class="col-6" v-for="child in item.children" :key="child"><router-link :to="'/category/'+child.id" class="navbar-subitem">{{ child.name }}</router-link></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 d-none d-lg-block">
                        <div class="container">
                            <div class="row">
                                <NavbarArticle class="col-6" v-for="article in item.articles" :key="article" :article="article"></NavbarArticle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </li>
</template>

<script>
import NavbarArticle from '@/components/Header/Navbar/NavbarArticle.vue';
export default {
    props: {
        item: Object
    },
    components: {
        NavbarArticle
    },
    data() {
        return {
            showSubMenu: false
        }
    },
    methods: {
        updateShowSubMenu(mode) {
            this.showSubMenu = mode
        },
        getImageUrl(imagePath) {
            return require(`@/assets/categories/navbar/${imagePath}.png`);
        }
    }
}
</script>
<style scoped>
.cover {
    z-index: 1;
    width: 70%;
    position: inherit;
    padding: 5% 10%;
}

.navbar-item-submenu .title {
    background: #ffffff;
    width: 100%;
    padding: 5px;
}

.navbar-subitem {
    color: floralwhite;
    text-decoration: none;
}

.navbar-subitem:hover {
    text-shadow: 1px 1px 1px #fff;
}

img.img-fit {
    object-fit: contain;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}
.navbar-item {
    font-family: serif;
    font-size: 18px;
    text-decoration: none;
    color: black;
}

.navbar-item.router-link-active {
}

.navbar-item-submenu {
    height: 210px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

@keyframes fade-in {
  from {
    margin-top: -5px;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    margin-top: 0px;
    opacity: 1;
  }
  to {
    margin-top: -5px;
    opacity: 0;
  }
}

.fade-enter-active {
  animation: fade-in 0.3s ease-in-out;
}

.fade-leave-active {
  animation: fade-out 0.3s ease-in-out;
}
</style>