<template>
    <div>
        <SectionHeader :name="category.name" :seperatorColor="seperatorColor"></SectionHeader>
        <div>
          <div class="row g-4">
            <template v-for="article in categoriesArticles[category.id]" :key="article">
              <Article_Vertical :article="article" class="col-xxl-20 col-xl-3 col-lg-4 col-sm-6" :borderSeperator="false"></Article_Vertical>
            </template>
          </div>
        </div>
    </div>
</template>
<script>
  /* eslint-disable */
  import SectionHeader from '@/components/SectionHeader.vue';
  import Article_Vertical from './Articles/Article_Vertical.vue';
  import Main_Article from '@/components/Section/Articles/Article_Main.vue'

  export default {
    components: {
      Main_Article,
      Article_Vertical,
      SectionHeader
    },
    props: {
      category: Object,
      seperatorColor: {
          type: String,
          default: () => "crimson"
      }
    },
    computed: {
      categoriesArticles() {
        return this.$store.state.categoriesArticles;
      }
    }
  }
</script>
<style scoped>
  @media (min-width: 1400px) {
    .col-xxl-20 {
      width: 20%;
    }
  }
</style>