<template>
    <div class="container-fluid p-0">
        <div class="slider-container">
            <div class="category-slider">
                <div v-for="category in categories" :key="category">
                    <a :href="'/category/' + category.id" class="category-slider-content position-relative d-block">
                        <div class="image-container">
                            <img :src="getImageUrl(category)" />
                        </div>
                        <span class="img-text"><i>{{ category.name }}</i></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    computed: {
        categories() {
            return this.$store.state.categoriesById[1].children;
        }
    },
    methods: {
        getImageUrl(category) {
            try {
                return require(`@/assets/categories/slider/${category.id}.webp`);
            } catch (e) {
                return category.id + " " + category.name;
            }
        }
    },
    mounted() {
        $('.category-slider').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            pauseOnHover: true
        });
    }
}
</script>
<style>
.category-slider .img-text {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    font-family: "Open Sans Condensed", Sans-serif;
    text-shadow: 1px 1px 1px #000;
    transition: transform 0.3s ease-in-out;
}

.category-slider-content:hover span {
  transform: translateY(-10px); /* Move span element up on hover */
}

.category-slider {
    overflow: hidden;
}

.category-slider .slick-prev {
    left: 10px;
}

.category-slider .slick-next {
    right: 30px;
}

.category-slider .slick-prev:before, .category-slider .slick-next:before {
    font-size: 40px;
}

.category-slider .slick-prev,
.category-slider .slick-next {
    z-index: 999;
}

.category-slider .slick-slide {
    margin-left: calc(1rem * 0.5);
    margin-right: calc(1rem * 0.5);
}

.category-slider .image-container {
  position: relative;
  width: 100%;
  padding-bottom: 70%; /* 75% = 16:9 aspect ratio (9 / 16 * 100) */
  overflow: hidden;
  border: 3px solid #000;
}

.category-slider .image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  width: 100%;
}

.category-slider-content .image-container::after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  transition: box-shadow 0.3s ease-in-out; /* Add transition for smooth effect */
}

.category-slider-content .image-container:hover::after {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>