<template>
    <div class="section-header">
        <span class="section-title">{{ name }}</span>
        <div class="section-seperator" :style="{'backgroundColor': seperatorColor}">
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            name: String,
            seperatorColor: {
                type: String,
                default: () => "crimson"
            }
        }
    }
</script>
<style scoped>
.section-header {
  position: relative;
  display: flex;
  align-items: center;
}

.section-title {
  background: white;
  padding: 5px 30px 5px 5px;
  font-size: 25px;
}

.section-seperator {
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: calc(50% - 6px);
  z-index: -1;
}
</style>