<template>
  <div>
      <SectionHeader :name="category.name" :seperatorColor="seperatorColor"></SectionHeader>
      <div>
        <div class="row g-2">
          <template v-for="(article, index) in categoriesArticles[category.id]" :key="article">
            <Main_Article :article="article" class="col-12" v-if="index == 0"></Main_Article>
            <Article_Vertical :article="article" class="col-lg-6" v-else></Article_Vertical>
          </template>
        </div>
      </div>
  </div>
</template>
<script>
/* eslint-disable */
import SectionHeader from '@/components/SectionHeader.vue';
import Article_Vertical from './Articles/Article_Vertical.vue';
import Main_Article from '@/components/Section/Articles/Article_Main.vue'

export default {
  components: {
    Main_Article,
    Article_Vertical,
    SectionHeader
  },
  props: {
    category: Object,
    seperatorColor: {
        type: String,
        default: () => "crimson"
    }
  },
  computed: {
    categoriesArticles() {
      return this.$store.state.categoriesArticles;
    }
  }
}
</script>