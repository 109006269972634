import { createStore } from 'vuex';
import { api } from '@/services/api.js'

/* eslint-disable */
export default createStore({
  state: {
    categories: [],
    categoriesArticles: {},
    categoriesById: {},
    rootCategories: {},
    loading: false,
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
      state.categoriesById = {};
      function addChildrenIds(category) {
        state.categoriesById[category.id] = category;
        if (category.children && category.children.length > 0) {
          category.children.forEach((child) => {
            addChildrenIds(child);
          });
        }
      }
      
      categories.forEach((category) => {
        addChildrenIds(category);
      });
      categories.forEach(function(category) {
        this.state.rootCategories[category.name] = category;
      }.bind(this));
    },
    setCategoriesArticles(state, data) {
      state.categoriesArticles = data;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      commit('setLoading', true);
      try {
        let categories = [];
        let categories_articles = {};
        [categories, categories_articles] = await Promise.all([
          api.get('categories'),
          api.get('categories/articles')
        ])
        commit('setCategories', categories);
        commit('setCategoriesArticles', categories_articles);
      } catch (error) {
        console.error(error);
      } finally {
        commit('setLoading', false);
      }
    },
  },
});