<template>
  <div class="article-horizontal">
    <div class="row g-0 mb-2">
      <div class="col-lg-4">
        <div class="thumbnail ratio-16x9">
          <router-link class="link" :to="getArticleLink(article)"><img :src="getThumbnail(article)" class="thumbnail-image" /></router-link>
        </div>
      </div>
      <div class="col-lg-8 p-3">
        <div class="article-title">
          <router-link class="link" :to="getArticleLink(article)">{{ article.title }}</router-link>
        </div>
        <div class="article-tools flex-wrap d-flex justify-content-between align-items-center">
          <router-link class="link" :to="getArticleLink(article)">Read More »</router-link>
          <small v-if="article.offer != null"><a :href="article.offer.link" class="link text-dark text-hover-underline">Visit {{ article.offer.brand_url }}</a></small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getThumbnail, getArticleLink } from '@/services/article'
export default {
  props: {
    article: Object
  },
  methods: {
    getThumbnail,
    getArticleLink
  }
}
</script>
<style scoped>
.article-horizontal {
  padding-top: 8px;
  border-bottom: 1px solid rgb(207, 207, 207);
}
.thumbnail {
  position: relative;
  overflow: hidden;
}

.thumbnail-image {
  width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.article-tools .link {
  font-weight: bold;
  text-decoration: none;
  color: #20547a;
  font-family: 'Cabin', sans-serif;
}
.article-tools .text-hover-underline {
  text-decoration: none;
}
.article-tools .text-hover-underline:hover {
  text-decoration: underline;
}

.article-title .link {
  text-decoration: none;
  color: inherit;
}

.article-title {
  text-overflow: ellipsis;
  font-weight: bold;
}
</style>